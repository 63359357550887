@import url("https://fonts.googleapis.com/css2?family=Bad+Script&family=Caladea&family=Faster+One&family=Lato&family=Nunito&family=Playfair+Display&family=Quintessential&family=Raleway&family=Tapestry&display=swap");


@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.fade-anim img {
  animation: fadeAnim 0.3s ease-out;
}

@keyframes fadeAnim {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


body {
  background: rgb(239, 240, 245);
  background: linear-gradient(
    100deg,
    rgba(231, 234, 255, 0.803) 0%,
    rgba(161, 147, 250, 0.518) 100%
  );
  /* background: #20202b; */
}
